import CancelIcon from '@mui/icons-material/Cancel';
import {
    Box,
    Card,
    Container,
    Divider,
    Grid,
    IconButton,
    Modal,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
import { CustomTabPanel } from '../../components/TabPanel';
import useErrorHandler from '../../hooks/useErrorHandler';
import usePagination from '../../hooks/usePagination';
import { server } from '../../utils/axios';

const ListExecutions = () => {
    const { scriptName } = useParams();
    const { paginationModel, setPaginationModel, offset, pageSize } =
        usePagination();

    const [rowCount, setRowCount] = useState(0);
    const errorHandler = useErrorHandler();
    const [selectedRow, setSelectedRow] = useState(null);
    const [tab, setTab] = useState('logs');

    const getExecutions = async () => {
        const params = {
            limit: pageSize,
            offset: offset,
            script_name: scriptName,
        };

        try {
            const response = await server.get(`/scripts/executions/`, {
                params,
            });
            const executions = response.data;

            setRowCount(executions.count);
            return executions.results;
        } catch (e) {
            errorHandler(e);
        }
    };

    const { data = [], isPending } = useQuery({
        queryKey: ['executions', scriptName, paginationModel],
        queryFn: getExecutions,
    });

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            sortable: false,
            width: 100,
        },
        {
            field: 'executed_at',
            headerName: 'Execution Time',
            sortable: false,
            width: 200,
        },
        {
            field: 'stderr',
            headerName: 'Have Error',
            width: 200,
            sortable: false,
            valueGetter: params => Boolean(params.row.stderr),
        },
        {
            field: 'stdout',
            headerName: 'Have Logs',
            width: 200,
            sortable: false,
            valueGetter: params => Boolean(params.row.stdout),
        },
    ];

    return (
        <Container maxWidth="false">
            <Box pt={3}>
                <Typography variant="h4" fontWeight={500}>
                    Executions
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ wordSpacing: '2px' }}>
                    See your script executions from this page
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={data}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode="server"
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                onRowClick={data => setSelectedRow(data.row)}
                disableSelectionOnClick
                loading={isPending}
                rowCount={rowCount}
                checkboxSelection={false}
            />

            <Modal
                open={Boolean(selectedRow)}
                onClose={() => setSelectedRow(null)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Card
                    sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
                        borderRadius: '18px',
                        overflowY: 'auto',
                        // minHeight: '30vh',
                        height: '90vh',
                        maxWidth: '1000px',
                        width: '100%',
                        mx: 2,
                        '::-webkit-scrollbar': { display: 'none' },
                        p: 2,
                    }}>
                    <Grid container>
                        <Grid item xs>
                            <Tabs
                                value={tab}
                                onChange={(e, newValue) => setTab(newValue)}>
                                <Tab label="Logs" value="logs" />
                                <Tab label="Error" value="errors" />
                            </Tabs>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => setSelectedRow(null)}>
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mb: 3 }} />
                    <CustomTabPanel value="logs" index={tab}>
                        <pre>{selectedRow?.stdout}</pre>
                    </CustomTabPanel>
                    <CustomTabPanel value="errors" index={tab}>
                        <pre>{selectedRow?.stderr}</pre>
                    </CustomTabPanel>
                </Card>
            </Modal>
        </Container>
    );
};

export default ListExecutions;
