import { Delete as DeleteIcon, Key as KeyIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { forwardRef, useEffect, useState } from 'react';
import { useMessage } from '../../components/Header';
import { SelectWithSearch } from '../../components/Select';
import useErrorHandler from '../../hooks/useErrorHandler';
import useLoader from '../../hooks/useLoader';
import { server } from '../../utils/axios';
import { getArrayFromObject, getObjectFromArray } from './utils/functions';

export default forwardRef(function CreateItem(props, ref) {
  const [product, setProduct] = useState('');
  const [search, setSearch] = useState('');
  const [attributes, setAttributes] = useState([]);
  const { closeModal, orderId, itemEventListener, orderItem } = props;
  const mode = orderItem ? 'edit' : 'create';
  const errorHandler = useErrorHandler();
  const { showSuccess } = useMessage();
  const { startLoading, endLoading, circular, loaderState } = useLoader();

  const onAttributeChange = type => e => {
    const { name, value } = e.target;
    const attributeIndex = Number(name);

    attributes[attributeIndex][type] = value;
    setAttributes([...attributes]);
  };

  const addItem = async e => {
    e.preventDefault();
    startLoading();
    try {
      const response = await server.post('/order-items/', {
        product: product.id,
        order: orderId,
        more_attributes: getObjectFromArray(attributes, 'name', 'value'),
      });

      showSuccess('Item added successfully');
      response.data.product = product;
      itemEventListener.emit('add', response.data);

      console.log(response.data);
      closeModal();
    } catch (e) {
      errorHandler(e);
    }
    endLoading();
  };

  async function editItem(e) {
    e.preventDefault();
    startLoading();
    try {
      const response = await server.patch(`/order-items/${orderItem.id}`, {
        more_attributes: getObjectFromArray(attributes, 'name', 'value'),
      });

      showSuccess('Item edited successfully');
      response.data.product = product;
      itemEventListener.emit('edit', response.data);

      console.log(response.data);
      closeModal();
    } catch (e) {
      errorHandler(e);
    }
    endLoading();
  }

  function selectProduct(e) {
    const product = e.target.value;
    console.log({ product });
    setProduct(product);
  }

  function addNewPair() {
    setAttributes([...attributes, { name: '', value: '' }]);
  }

  const removePair = index => () => {
    attributes.splice(index, 1);
    setAttributes([...attributes]);
  };

  const { data: products = [], isFetching } = useQuery({
    queryKey: ['products', search],
    queryFn: async () => {
      const params = { search };
      const response = await server.get(`/products/`, { params });
      return response.data.results;
    },
    onError: e => errorHandler(e),
  });

  useEffect(() => {
    if (mode === 'create') return;

    setProduct(orderItem.product);
    setAttributes(
      getArrayFromObject(orderItem.more_attributes, 'name', 'value')
    );
  }, [mode, orderItem]);

  return (
    <Card
      ref={ref}
      sx={{
        boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
        borderRadius: '18px',
        overflowY: 'auto',
        maxWidth: '800px',
        maxHeight: '80vh',
        width: '100%',
        mx: 2,
        '::-webkit-scrollbar': { display: 'none' },
        p: 2,
      }}>
      <Box
        sx={{
          p: 3,
          color: 'text.secondary',
          fontWeight: 500,
          letterSpacing: '1.2px',
          fontSize: '14px',
        }}>
        <Typography variant="h5" fontWeight={600} color="primary" mb={2}>
          {mode === 'edit' ? 'Edit' : 'Add'} Order Item
        </Typography>

        <form>
          {mode === 'create' && (
            <>
              <Typography variant="body2" gutterBottom>
                Product
              </Typography>
              <SelectWithSearch
                displayEmpty
                name="order-item"
                onChange={selectProduct}
                value={product}
                fullWidth
                SearchProps={{
                  onChange: e => setSearch(e.target.value),
                  isLoading: isFetching,
                }}>
                {products.map(product => (
                  <MenuItem value={product} key={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </SelectWithSearch>
            </>
          )}

          {product && (
            <Box
              sx={{
                p: 2,
                border: '1px solid #ccc',
                borderRadius: 2,
                width: '100%',
              }}>
              <Grid
                container
                sx={{
                  alignItems: 'center',
                  mb: 2,
                }}>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  ATTRIBUTES
                </Typography>
                <Tooltip title="Tooltip information">
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={addNewPair}>
                    Add New Pair
                  </Button>
                </Tooltip>
              </Grid>
              {attributes.map((attribute, i) => (
                <Stack
                  key={i}
                  direction="row"
                  gap={2}
                  mb={2}
                  alignItems="center">
                  <KeyIcon sx={{ transform: 'rotate(45deg)' }} />
                  <TextField
                    variant="outlined"
                    placeholder="Attribute Name"
                    name={i}
                    value={attribute.name}
                    onChange={onAttributeChange('name')}
                    fullWidth
                  />
                  <TextField
                    variant="outlined"
                    disabled={loaderState}
                    name={i}
                    value={attribute.value}
                    onChange={onAttributeChange('value')}
                    placeholder="Attribute Value"
                    fullWidth
                  />
                  <IconButton onClick={removePair(i)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              ))}
            </Box>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}>
            <Box textAlign="center">
              <Button
                variant="contained"
                type="submit"
                disabled={!product || loaderState}
                onClick={mode === 'edit' ? editItem : addItem}
                sx={{
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  padding: '12px 28px',
                }}
                endIcon={circular}>
                {mode === 'edit' ? 'Edit' : 'Add'} Item
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
    </Card>
  );
});
