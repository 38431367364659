import React from 'react';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';

const OptionsEditor = ({
  title,
  description,
  options,
  value,
  onSelect,
  isPending,
}) => {
  return (
    <Box mb={2}>
      <Typography textTransform="capitalize" variant="body1" fontWeight={600}>
        {title}
      </Typography>
      <Typography variant="body2">{description}</Typography>
      <Box mt={1}>
        <ButtonGroup variant="contained" aria-label="option button group">
          {options.map(option => (
            <Button
              key={option}
              disabled={isPending}
              variant={option === value ? 'contained' : 'outlined'}
              color={option === value ? 'primary' : undefined}
              onClick={() => {
                if (option !== value) {
                  onSelect(option);
                }
              }}>
              {option}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default OptionsEditor;
