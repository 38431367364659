import Editor from '@monaco-editor/react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessage } from '../../components/Header';
import Loading from '../../components/Loading';
import useErrorHandler from '../../hooks/useErrorHandler';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import useLoader from '../../hooks/useLoader';
import useModal from '../../hooks/useModal';
import { server } from '../../utils/axios';
import DeleteModal from './DeleteModal';
import CreateGenerator from './CreateGenerator';
// import { escapeDanger } from '../../utils/function';

const NewProduct = () => {
  const { productId } = useParams();
  const isEditMode = Boolean(productId);
  const { showSuccess } = useMessage();
  const { startLoading, endLoading, loaderState } = useLoader();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const deleteModal = useModal();
  const AddGeneratorModal = useModal();

  const handlers = useForm(
    useMemo(
      () => ({
        id: {},
        name: { required: true },
        shopify_id: {
          required: true,
          validator: value => isNaN(value) && 'Should be a number',
        },
        folder: { required: true },
        auto_generate: { value: true },
        script: {},
      }),
      []
    ),
    { Input: TextField }
  );

  const setProductValues = handlers.setValues;

  const codeChangeHandler = value => setProductValues({ script: value });

  const checkBoxChangeHandler = e => {
    console.log(`${e.target.name} ${e.target.checked}`);
    setProductValues({ [e.target.name]: e.target.checked });
  };

  const getProduct = useCallback(async () => {
    startLoading();
    try {
      const response = await server.get(`/products/${productId}`);

      const { id, name, shopify_id, folder, script, auto_generate } =
        response.data;

      setProductValues({
        id,
        name,
        shopify_id,
        folder,
        auto_generate,
        script,
      });
    } catch (e) {
      errorHandler(e);
    } finally {
      endLoading();
    }
  }, [productId, setProductValues, startLoading, endLoading, errorHandler]);

  const onSubmit = response => {
    if (response.data) showSuccess('Product saved successfully');
    navigate(-1);
  };

  useEffect(() => {
    if (!isEditMode) return;

    getProduct();
  }, [isEditMode, getProduct]);

  return (
    <Box
      bgcolor="background.paper"
      height="calc(100vh - 76px)"
      overflow="auto"
      display="flex"
      flexDirection="column"
      p={0}>
      <Grid container alignItems="center" sx={{ pr: 2 }}>
        <Grid item xs>
          <Typography variant="h5" fontWeight={600} color="primary" p={2}>
            {isEditMode ? `Edit Product` : `New Product`}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={AddGeneratorModal.openModal}
            endIcon={<AddIcon />}>
            Add Generator
          </Button>
          {productId && (
            <Button
              variant="outlined"
              color="error"
              onClick={deleteModal.openModal}
              endIcon={<DeleteIcon />}>
              Delete
            </Button>
          )}
        </Grid>
      </Grid>
      <Divider variant="fullWidth" />
      {loaderState ? (
        <Loading message="Please wait, while your data is loading..." />
      ) : (
        <Form
          handlers={handlers}
          onSubmit={onSubmit}
          action={isEditMode ? `/products/${productId}` : '/products/'}
          method={isEditMode ? 'patch' : 'post'}
          axiosInstance={server}
          retainOnSubmit
          onError={errorHandler}
          style={{ flexGrow: 1, position: 'relative' }}>
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            mb={4}
            overflow="auto">
            <Grid
              container
              columnSpacing={5}
              rowSpacing={1}
              alignItems="center"
              mb={2}>
              <Grid item xs={12} lg>
                <Typography variant="body2" fontWeight={600}>
                  Name
                </Typography>
              </Grid>

              <Grid item xs={12} lg={10} display="flex">
                <Input
                  name="name"
                  variant="outlined"
                  size="small"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '90%',
                      lg: '316px',
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              columnSpacing={5}
              rowSpacing={1}
              alignItems="center"
              mb={2}>
              <Grid item xs={12} lg>
                <Typography variant="body2" fontWeight={600}>
                  Shopify Id
                </Typography>
              </Grid>

              <Grid item xs={12} lg={10} display="flex">
                <Input
                  name="shopify_id"
                  type="number"
                  variant="outlined"
                  size="small"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '90%',
                      lg: '316px',
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              columnSpacing={5}
              rowSpacing={1}
              alignItems="center"
              mb={2}>
              <Grid item xs={12} lg>
                <Typography variant="body2" fontWeight={600}>
                  Folder
                </Typography>
              </Grid>

              <Grid item xs={12} lg={10} display="flex">
                <Input
                  name="folder"
                  variant="outlined"
                  size="small"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '90%',
                      lg: '316px',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={5}
              rowSpacing={1}
              alignItems="center"
              mb={2}>
              <Grid item xs={12} lg>
                <Typography variant="body2" fontWeight={600}>
                  Script Enabled
                </Typography>
              </Grid>

              <Grid item xs={12} lg={10} display="flex">
                <Checkbox
                  name="auto_generate"
                  checked={handlers.values.auto_generate}
                  onChange={checkBoxChangeHandler}
                />

                {/* <Input
                                    name="auto_generate"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                /> */}
              </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ my: 2 }} />
          </Box>
          {handlers.values.auto_generate && (
            <Grid container>
              <Grid item xs={9}>
                {isEditMode && (
                  <Editor
                    height="90vh"
                    defaultValue="// Code goes here, happy scripting ;)"
                    defaultLanguage="javascript"
                    value={handlers.values.script}
                    onChange={codeChangeHandler}
                  />
                )}
              </Grid>
              <Grid item xs>
                {/* <TestScriptSection product={handlers.values} /> */}
              </Grid>
            </Grid>
          )}
          <Stack
            direction="row"
            p={2}
            spacing={2}
            sx={{
              boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
              backgroundColor: 'background.paper',
              zIndex: 1100,
              position: 'fixed',
              bottom: 0,
              width: {
                xs: '100%',
                xm: `calc(100% - 280px)`,
              },
            }}>
            <Submit>
              {loader => (
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={Boolean(loader)}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  endIcon={loader}>
                  Save
                </Button>
              )}
            </Submit>
            <Button
              onClick={() => navigate(-1)}
              sx={{
                bgcolor: 'background.default',
                border: '1px solid',
                borderColor: 'divider',
              }}>
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
      <DeleteModal modal={deleteModal} productId={productId} />
      <CreateGenerator modal={AddGeneratorModal} productId={productId} />
    </Box>
  );
};

export default NewProduct;

// function TestScriptSection(props, ref) {
//     const [attributeValues, setAttributeValues] = useState({});
//     const { product, attributes } = props;
//     const errorHandler = useErrorHandler();
//     const { showSuccess } = useMessage();
//     const { start, end, circular, loaderState } = useLoader();
//     const { modalState, closeModal, openModal } = useModal();
//     const [script, setScript] = useState({ console: [] });
//     const [consoleIndex, setConsoleIndex] = useState(1);

//     console.log(product);
//     console.log(attributes);

//     const handleIndexChange = (event, value) => {
//         setConsoleIndex(value);
//     };

//     const onAttributeValueChange = e => {
//         const { name, value } = e.target;
//         setAttributeValues({ ...attributeValues, [name]: value });
//     };

//     const testScript = async e => {
//         e.preventDefault();
//         start();
//         try {
//             const response = await server.post(
//                 `/test-script/${product.id}`,
//                 attributeValues
//             );

//             showSuccess('Script ran successfully');
//             setScript({
//                 console: ['', ...response.data.console],
//                 svg: response.data.svg,
//             });
//             console.log(response.data);
//             openModal();
//         } catch (e) {
//             errorHandler(e);
//         }
//         end();
//     };

//     console.log(attributeValues);

//     return (
//         <Box
//             sx={{
//                 px: 3,
//                 color: 'text.secondary',
//                 fontWeight: 500,
//                 letterSpacing: '1.2px',
//                 fontSize: '14px',
//             }}>
//             <Typography variant="h5" fontWeight={600} color="primary" mb={2}>
//                 Test Script
//             </Typography>

//             {/* <form>
//                 {attributes.map(attr => (
//                     <React.Fragment key={attr.name}>
//                         <Typography variant="body2" gutterBottom>
//                             {attr.name}
//                         </Typography>

//                         <TextField
//                             disabled={loaderState}
//                             fullWidth
//                             name={attr.name}
//                             type={attr.datatype}
//                             value={attributeValues[attr.name]}
//                             required={attr.required}
//                             onChange={onAttributeValueChange}
//                             sx={{ mb: 2 }}
//                         />
//                     </React.Fragment>
//                 ))}

//                 <Stack
//                     direction="row"
//                     justifyContent="space-between"
//                     alignItems="center"
//                     mt={2}>
//                     <Box textAlign="center">
//                         <Button
//                             variant="contained"
//                             type="submit"
//                             onClick={testScript}
//                             sx={{
//                                 fontWeight: '500',
//                                 textTransform: 'capitalize',
//                                 borderRadius: '8px',
//                                 padding: '12px 28px',
//                             }}
//                             endIcon={circular}>
//                             Test Script
//                         </Button>
//                     </Box>
//                 </Stack>
//             </form> */}

//             <Modal
//                 open={modalState}
//                 onClose={closeModal}
//                 sx={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                 }}>
//                 <Card
//                     sx={{
//                         p: 2,
//                         maxWidth: '1200px',
//                         width: '100%',
//                         maxHeight: '90dvh',
//                         alignItems: 'flex-start',
//                         overflow: 'auto',
//                     }}>
//                     <Box dangerouslySetInnerHTML={escapeDanger(script.svg)} />

//                     <Typography
//                         variant="subtitle1"
//                         gutterBottom
//                         fontWeight={500}>
//                         Console
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                         {script.console[consoleIndex]}
//                     </Typography>
//                     <Stack
//                         direction="row"
//                         justifyContent="space-between"
//                         alignItems="center">
//                         <Pagination
//                             page={consoleIndex}
//                             count={script.console.length - 1}
//                             onChange={handleIndexChange}
//                         />
//                         <Button variant="outlined" onClick={closeModal}>
//                             Close
//                         </Button>
//                     </Stack>
//                 </Card>
//             </Modal>
//         </Box>
//     );
// }
