import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, Typography, Card, CardContent, Tabs, Tab } from '@mui/material';
import { formatTimeAgo } from '../../utils/function';
import { server } from '../../utils/axios';

const Logs = ({ orderId }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const fetchLogs = async () => {
    const response = await server.get(
      `/logs/?related_to=order,laser_queue&related_id=${orderId}`
    );
    return response.data;
  };

  const fetchHistory = async () => {
    const response = await server.get(`/orders/${orderId}/history/`);
    return response.data;
  };

  const {
    data: logs,
    isLoading: isLoadingLogs,
    isError: isErrorLogs,
  } = useQuery({
    queryKey: ['logs', orderId],
    queryFn: fetchLogs,
    onError: error => {
      console.error('Error fetching logs:', error);
    },
  });

  const {
    data: history,
    isLoading: isLoadingHistory,
    isError: isErrorHistory,
  } = useQuery({
    queryKey: ['history', orderId],
    queryFn: fetchHistory,
    enabled: tabIndex === 1,
    onError: error => {
      console.error('Error fetching history:', error);
    },
  });

  return (
    <Card
      sx={{
        minHeight: 350,
        border: '1px solid #d9d9d9',
        borderRadius: '5px',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <CardContent>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="Logs and History Tabs">
          <Tab label="Logs" />
          <Tab label="History" />
        </Tabs>

        {tabIndex === 0 && (
          <Box mt={2}>
            {isLoadingLogs ? (
              <Typography variant="body2">Loading logs...</Typography>
            ) : isErrorLogs ? (
              <Typography variant="body2" color="error">
                Error loading logs.
              </Typography>
            ) : (
              <Box
                sx={{
                  mt: 2,
                  maxHeight: '260px',
                  overflowY: 'auto',
                }}>
                {logs?.results.length ? (
                  logs.results.map((log, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <Box
                        sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              textTransform: 'capitalize',
                              marginLeft: 1,
                            }}>
                            {log.data.message}
                          </Typography>
                          <Box>
                            <Typography
                              fontWeight="bold"
                              variant="body2"
                              color="text.secondary"
                              sx={{ marginLeft: 1 }}>
                              {formatTimeAgo(log.date_created)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box textAlign="center" mt={8}>
                    <Typography variant="h6">No Logs yet.</Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}

        {tabIndex === 1 && (
          <Box mt={2}>
            {isLoadingHistory ? (
              <Typography variant="body2">Loading history...</Typography>
            ) : isErrorHistory ? (
              <Typography variant="body2" color="error">
                Error loading history.
              </Typography>
            ) : (
              <Box
                sx={{
                  mt: 2,
                  maxHeight: '260px',
                  overflowY: 'auto',
                }}>
                {history.length ? (
                  history.map(entry => (
                    <Box key={entry.revision_id} sx={{ mb: 1 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 0.5,
                        }}>
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{
                            textTransform: 'capitalize',
                          }}>
                          {entry.user ? entry.user : 'N/A'}
                        </Typography>
                        <Typography
                          fontWeight="bold"
                          variant="body2"
                          color={'text.secondary'}
                          sx={{
                            marginLeft: 1,
                          }}>
                          {formatTimeAgo(entry.date)}
                        </Typography>
                      </Box>

                      <Typography variant="body2">{entry.comment}</Typography>
                    </Box>
                  ))
                ) : (
                  <Box textAlign="center" mt={8}>
                    <Typography variant="h6">No History yet.</Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Logs;
