import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
import useErrorHandler from '../../hooks/useErrorHandler';
import useLoader from '../../hooks/useLoader';
import { server } from '../../utils/axios';
import EditIcon from '@mui/icons-material/Edit';
import { useMessage } from '../../components/Header';

const Index = () => {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState('');
  const { showSuccess } = useMessage();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const { startLoading: start, endLoading: end, loaderState } = useLoader();
  const errorHandler = useErrorHandler();

  const getTriggers = useCallback(async () => {
    start();
    setRows([]);

    const params = {
      limit: paginationModel.pageSize,
      offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
    };

    if (search) params.search = search;

    try {
      const response = await server.get(`/triggers/`, { params });
      const triggers = response.data;

      setRows(triggers);
      setRowCount(triggers.count);
    } catch (e) {
      errorHandler(e);
    } finally {
      end();
    }
  }, [paginationModel, search, start, end, errorHandler]);

  useEffect(() => {
    getTriggers();
  }, [getTriggers]);

  const handleToggleStatus = async (id, currentStatus) => {
    try {
      await server.patch(`/triggers/${id}/`, {
        enabled: !currentStatus,
      });
      getTriggers(); // Refresh data after toggle
      showSuccess('Triggers updated successfully');
    } catch (e) {
      errorHandler(e);
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: false,
      width: 50,
    },
    {
      headerName: 'Action',
      sortable: false,
      width: 70,
      renderCell: params => (
        <IconButton
          LinkComponent={NavLink}
          to={`/triggers/edit/${params.row.id}/`}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'enabled',
      headerName: 'Enable/Disable',
      sortable: false,
      width: 90,
      renderCell: params => (
        <Switch
          checked={params.row.enabled}
          onChange={() => handleToggleStatus(params.row.id, params.row.enabled)}
          color="primary"
        />
      ),
    },
    {
      field: 'Trigger action',
      headerName: 'Trigger Action',
      sortable: false,
      width: 100,
      renderCell: params => (
        <Typography variant="body2">{params.row.action}</Typography>
      ),
    },
    {
      field: 'event',
      headerName: 'Event',
      sortable: false,
      width: 140,
      renderCell: params => (
        <Typography variant="body2">{params.row.event}</Typography>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      sortable: false,
      width: 160,
      renderCell: params => (
        <Typography variant="body2">
          {new Date(params.row.created_at).toLocaleString()}
        </Typography>
      ),
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      sortable: false,
      width: 160,
      renderCell: params => (
        <Typography variant="body2">
          {new Date(params.row.updated_at).toLocaleString()}
        </Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      // width: 500,
      flex: 1,
      renderCell: params => (
        <Typography
          variant="subtitle2"
          fontWeight="600"
          textTransform="capitalize"
          sx={{ color: 'primary.main' }}>
          {params.row.description}
        </Typography>
      ),
    },
  ];

  return (
    <Container maxWidth="false">
      <Box pt={3}>
        <Typography variant="h4" fontWeight={500}>
          Triggers
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ wordSpacing: '2px' }}>
          Manage your triggers from this page
        </Typography>
        <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
        autoHeight
        disableSelectionOnClick
        loading={loaderState}
        rowCount={rowCount}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: { setSearch, search },
        }}
        checkboxSelection={false}
      />
    </Container>
  );
};

export function CustomToolbar({ setSearch, search }) {
  const navigate = useNavigate();

  return (
    <GridToolbarContainer sx={{ pb: '24px' }}>
      <Grid container alignItems="center" width="100%" spacing={1}>
        <Grid item xs>
          {/* <SearchBar
                          
                          value={search}
                          onChange={e => setSearch(e.target.value)}
                      /> */}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate('new')}>
            Create Trigger
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default Index;
