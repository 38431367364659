import { Box, Container, Divider, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { createContext, useMemo } from 'react';
import { useMessage } from '../../components/Header';
import { useSettings } from '../../hooks/Authorize';
import { Form, useForm } from '../../hooks/useForm';
import { server } from '../../utils/axios';
import Editor from './Editors/Editor';

const SettingContext = createContext({});

const Index = () => {
  const settingsModule = useSettings();
  const settings = settingsModule.all();
  const { showError, showSuccess } = useMessage();

  const handlers = useForm(
    useMemo(
      () => ({
        key: { required: true },
        value: { required: true },
      }),
      []
    ),
    { Input: TextField }
  );

  const getSetting = settingsModule.fetchSettings;

  const updateMutation = useMutation({
    mutationFn: async variables => {
      const { key, ...rest } = variables;
      return (await server.patch(`/settings/${variables.key}/`, rest)).data;
    },
    onSettled: getSetting,
  });

  const saveSettings = (index, key, value) => {
    updateMutation.mutate(
      { key, value },
      {
        onSuccess: () => showSuccess('Settings saved successfully'),
        onError: () => showError('Failed to save settings'),
      }
    );
  };

  return (
    <SettingContext.Provider value={{ updateMutation }}>
      <Container maxWidth="false">
        <Box pt={3}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Box>
              <Typography variant="h4" fontWeight={500}>
                Settings
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ wordSpacing: '2px' }}>
                Manage your settings from this page
              </Typography>
            </Box>
          </Box>
          <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
          <Form
            handlers={handlers}
            style={{ flexGrow: 1, position: 'relative' }}>
            <Box
              p={2}
              display="flex"
              flexDirection="column"
              mb={4}
              overflow="auto">
              {settings.map((setting, index) => (
                <Editor
                  key={setting.key}
                  setting={setting}
                  index={index}
                  handlers={handlers}
                  saveSettings={saveSettings}
                  updateMutation={updateMutation}
                />
              ))}
            </Box>
          </Form>
        </Box>
      </Container>
    </SettingContext.Provider>
  );
};

export default Index;
export { SettingContext };
