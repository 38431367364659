import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Input } from '../../../hooks/useForm/inputs/index';

const StringEditor = ({ name, value, description, onChange, onSave }) => {
  return (
    <Box mb={2}>
      <Typography textTransform="capitalize" variant="body1" fontWeight={600}>
        {name}
      </Typography>
      <Typography variant="body2">{description}</Typography>
      <Box display="flex" alignItems="center" mt={1}>
        <Input
          name={name}
          variant="outlined"
          size="small"
          defaultValue={value}
          onChange={onChange}
          sx={{
            width: {
              xs: '100%',
              lg: '400px',
            },
            my: 0.5,
          }}
        />
        <IconButton sx={{ ml: 2 }} onClick={onSave}>
          <EditOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default StringEditor;
