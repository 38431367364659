import Add from '@mui/icons-material/Add';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EventEmitter } from 'events';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useMessage } from '../../components/Header';
import Loading from '../../components/Loading';
import { useSettings } from '../../hooks/Authorize';
import useErrorHandler from '../../hooks/useErrorHandler';
import useModal from '../../hooks/useModal';
import { server } from '../../utils/axios';
import { CreateEditOrder } from './CreateEditOrder';
import CreateItem from './CreateItems';
import OrderItem from './OrderItem';
import ActionIcon from '../../components/ActionIcon';
import CachedIcon from '@mui/icons-material/Cached';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import { formatTimeAgo } from '../../utils/function';
import Importantcomments from './Importantcomments';
import Logs from './Logs';

const itemEventListener = new EventEmitter();

const NewOrder = () => {
  const { orderId } = useParams();
  const isEditMode = Boolean(orderId);
  const { showSuccess, showError } = useMessage();
  const errorHandler = useErrorHandler();
  const { openModal, closeModal, modalState } = useModal();
  const orderEditModal = useModal();
  const orderImporantCommentModal = useModal();
  const [orderItems, setOrderItems] = useState([]);
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const settings = useSettings();
  const orderStatus = settings.getValue('order-statuses');
  const trackingUrl = settings.getValue('tracking-url');

  const orderStatuses =
    typeof orderStatus === 'string'
      ? orderStatus.split(',')
      : orderStatus || [];
  const queryClient = useQueryClient();
  const [newComment, setNewComment] = useState('');
  const [importantDisplayed, setImporantDisplayed] = useState(false);

  const {
    data = {},
    isPending,
    isFetching,
  } = useQuery({
    queryKey: ['order', orderId],
    queryFn: async () => {
      if (!orderId) return {};

      const response = await server.get(`/orders/${orderId}`, {
        params: { expand: 'customer' },
      });

      return response.data;
    },
    onError: e => errorHandler(e),
  });

  const updateStatusMutation = useMutation({
    mutationFn: async newStatus => {
      return await server.patch(`/orders/${orderId}`, {
        status: newStatus,
      });
    },
    onMutate: data => {
      queryClient.cancelQueries(['order', orderId]);

      const prevOrder = queryClient.getQueryData(['order', orderId]);

      queryClient.setQueryData(['order', orderId], old => ({
        ...old,
        status: data,
      }));
      return { prevOrder };
    },
    onSuccess: () => {
      showSuccess('Order status updated successfully');
      queryClient.invalidateQueries(['logs', orderId]);
    },
    onError: (err, updatedOrder, context) => {
      queryClient.setQueryData(['order', orderId], context.prevOrder);
      showError('Failed to update status');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['order', orderId]);
    },
  });

  const getOrderItems = useCallback(async () => {
    if (!isEditMode) return;
    setOrderItems([]);

    try {
      const response = await server.get(`/order-items/`, {
        params: { order: orderId, expand: 'product' },
      });
      const orderItems = response.data;

      setOrderItems(orderItems.results);
    } catch (e) {
      errorHandler(e);
    }
  }, [errorHandler, isEditMode, orderId]);

  const openOrderItemEditModal = function (orderItem) {
    setSelectedOrderItem(orderItem);
    openModal();
  };

  const openOrderEditModal = function () {
    orderEditModal.openModal();
  };

  const closeOrderEditModal = function () {
    orderEditModal.closeModal();
  };

  const openCommetModal = function () {
    orderImporantCommentModal.openModal();
  };

  const closeCommetModal = function () {
    orderImporantCommentModal.closeModal();
  };

  const refresh = () => queryClient.invalidateQueries(['order', orderId]);

  useEffect(() => {
    const handleItemAdd = data => setOrderItems([...orderItems, data]);
    const handleItemEdit = orderItem => {
      const index = orderItems.findIndex(item => item.id === orderItem.id);
      orderItems[index] = orderItem;

      setOrderItems([...orderItems]);
    };
    itemEventListener.on('add', handleItemAdd);
    itemEventListener.on('edit', handleItemEdit);

    return () => {
      itemEventListener.removeListener('add', handleItemAdd);
      itemEventListener.removeListener('edit', handleItemEdit);
    };
  }, [orderItems]);

  // comments Query Methods

  const comments = useQuery({
    queryKey: ['comments', orderId],
    queryFn: async () => {
      const response = await server.get(`/comments/?order_id=${orderId}`);
      return response.data.results;
    },
    onError: e => errorHandler(e),
  });

  const importantComments = useMemo(
    () => comments.data?.filter(comment => comment.isImportant) || [],
    [comments]
  );

  // Users Query Methods
  const users = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await server.get(`/user/users/`);
      return response.data.results;
    },
    onError: e => errorHandler(e),
  });

  const onCommentSubmit = e => {
    e.preventDefault();

    if (!newComment.trim()) return;

    commentMutation.mutate();
  };

  const commentMutation = useMutation({
    mutationFn: async () => {
      await server.post(`/comments/?order_id=${orderId}`, {
        comment: newComment,
        order: orderId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['comments', orderId]);
      setNewComment('');
      showSuccess('New comment added');
    },
    onError: e => errorHandler(e),
  });

  const toggleImportantMutation = useMutation({
    mutationFn: async variables => {
      return await server.patch(`/comments/${variables.id}/`, {
        isImportant: !variables.status,
      });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['comments', orderId]);
      showSuccess(
        `Comment has been marked as ${
          !variables.status ? 'important' : 'unimportant'
        }.`
      );
    },
    onError: e => errorHandler(e),
  });
  console.log(settings.key);

  useEffect(() => {
    getOrderItems();
  }, [getOrderItems]);

  useEffect(() => {
    if (!comments.data) return;

    if (importantDisplayed) return;

    if (!importantComments.length) return;

    console.log('opening modal');

    orderImporantCommentModal.openModal();
    // isModalOpened.current = true; // Set flag to true after opening the modal

    setImporantDisplayed(true);
  }, [
    comments.data,
    importantDisplayed,
    orderImporantCommentModal,
    importantComments.length,
  ]);

  const trackingUrlWithId = trackingUrl.replace('{{id}}', data.tracking_id);

  return (
    <Box
      bgcolor="background.paper"
      height="calc(100vh - 76px)"
      overflow="auto"
      display="flex"
      flexDirection="column"
      p={0}>
      <Typography variant="h5" fontWeight={600} color="primary" p={2}>
        {isEditMode ? (
          <Grid container alignItems="center">
            <Grid item xs>
              Edit Order{' '}
              {isPending ? (
                <CircularProgress size={20} sx={{ ml: 1 }} />
              ) : data.shopify_id ? (
                `#${data.shopify_id} `
              ) : (
                '(Manual Order)'
              )}
            </Grid>
            {!isPending && (
              <>
                <Grid item sx={{ mr: 2 }}>
                  <Button
                    LinkComponent={NavLink}
                    variant="outlined"
                    target="_blank"
                    to={trackingUrlWithId}>
                    Track Order
                  </Button>
                </Grid>
                <Grid item>
                  <ActionIcon
                    title="Edit"
                    onClick={openOrderEditModal}
                    tooltipProps={{ placement: 'left' }}>
                    <EditIcon fontSize="small" sx={{ color: 'primary.main' }} />
                  </ActionIcon>
                </Grid>
                <Grid item>
                  <ActionIcon
                    title="Refresh"
                    onClick={refresh}
                    tooltipProps={{ placement: 'left' }}>
                    {isFetching ? (
                      <CircularProgress size={20} />
                    ) : (
                      <CachedIcon
                        fontSize="small"
                        sx={{ color: 'primary.main' }}
                      />
                    )}
                  </ActionIcon>
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          `New Order`
        )}
      </Typography>
      <Divider variant="fullWidth" />
      {isPending ? (
        <Loading message="Please wait, while your data is loading..." />
      ) : (
        <Box p={2}>
          {importantComments?.length > 0 && (
            <Box textAlign="right">
              <Button variant="contained" onClick={openCommetModal}>
                ! Important Comments
              </Button>
            </Box>
          )}

          <Grid container spacing={2} sx={{ mb: 3, mt: 2 }}>
            {/* Customer Information */}
            <Grid item lg={4} xs={12}>
              <Card
                sx={{
                  height: 294,
                  border: '1px solid #d9d9d9',
                  borderRadius: '5px',
                  boxShadow: 'none',
                }}>
                <CardContent>
                  <Box>
                    <Typography variant="h6" fontWeight={500}>
                      Customer Information
                    </Typography>
                    <Grid container spacing={2} mt={1}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                        <Typography variant="body1">
                          <strong>Name</strong>
                        </Typography>
                        <Typography>{data.customer_name}</Typography>
                      </Grid>
                      {data.customer_email && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                          <Typography variant="body1">
                            <strong>Email</strong>
                          </Typography>
                          <Typography>{data.customer_email}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Shipping Address */}
            <Grid item lg={4} xs={12}>
              <Card
                sx={{
                  height: 294,
                  border: '1px solid #d9d9d9',
                  borderRadius: '5px',
                  boxShadow: 'none',
                }}>
                <CardContent>
                  <Box>
                    <Typography variant="h6" fontWeight={500}>
                      Shipping Address
                    </Typography>
                    <Grid container spacing={2} mt={1}>
                      {data.shipping_address ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <Typography variant="body1">
                              <strong>Address</strong>
                            </Typography>
                            <Typography>
                              {data.shipping_address.address1}
                              {data.shipping_address.city}
                              {data.shipping_address.country}
                            </Typography>
                          </Grid>
                          {data.shipping_address.address2 && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <Typography variant="body1">
                                <strong>Address2</strong>
                              </Typography>
                              <Typography>
                                {data.shipping_address.address2},{' '}
                                {data.shipping_address.city},{' '}
                                {data.shipping_address.country}
                              </Typography>
                            </Grid>
                          )}
                          {data.shipping_address.phone && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <Typography variant="body1">
                                <strong>Phone</strong>
                              </Typography>
                              <Typography>
                                {data.shipping_address.phone}
                              </Typography>
                            </Grid>
                          )}
                          {data.shipping_address.company && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <Typography variant="body1">
                                <strong>Company</strong>
                              </Typography>
                              <Typography>
                                {data.shipping_address.company}
                              </Typography>
                            </Grid>
                          )}
                          {data.shipping_address.province && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <Typography variant="body1">
                                <strong>Province</strong>
                              </Typography>
                              <Typography>
                                {data.shipping_address.province}
                              </Typography>
                            </Grid>
                          )}
                          {data.shipping_address.zip && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <Typography variant="body1">
                                <strong>Postal Code</strong>
                              </Typography>
                              <Typography>
                                {data.shipping_address.zip}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      ) : (
                        <Box m={5} ml={15}>
                          <Typography>
                            No shipping address details available.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Order Information */}
            <Grid item lg={4} xs={12}>
              <Card
                sx={{
                  height: 294,
                  border: '1px solid #d9d9d9',
                  borderRadius: '5px',
                  boxShadow: 'none',
                }}>
                <CardContent>
                  <Box>
                    <Typography variant="h6" fontWeight={500}>
                      Order Information
                    </Typography>
                    <Grid container spacing={2} mt={1}>
                      {data ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <Typography variant="body1">
                              <strong>Order ID</strong>
                            </Typography>
                            <Typography>{data.shopify_id}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <Typography variant="body1">
                              <strong>Order Date</strong>
                            </Typography>
                            <Typography>
                              {new Date(data.created_on).toLocaleDateString()}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <Typography variant="body1">
                              <strong>Financial Status</strong>
                            </Typography>
                            <Typography>{data.financial_status}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <Typography variant="body1">
                              <strong>Total Price</strong>
                            </Typography>
                            <Typography>€{data.total_price}</Typography>
                          </Grid>
                          {data.note && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <Typography variant="body1">
                                <strong>Note</strong>
                              </Typography>
                              <Typography>{data.note}</Typography>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <Typography variant="body1">
                              <strong>Status</strong>
                            </Typography>
                            <Select
                              value={data.status}
                              disabled={updateStatusMutation.isPending}
                              onChange={e =>
                                updateStatusMutation.mutate(e.target.value)
                              }>
                              {orderStatuses.map(status => (
                                <MenuItem value={status}>{status}</MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </>
                      ) : (
                        <Box m={5} ml={15}>
                          <Typography>No order details available.</Typography>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Box display="flex" flexDirection="column" mb={4} overflow="auto">
                {isEditMode && (
                  <>
                    {orderItems.map(orderItem => (
                      <OrderItem
                        key={orderItem.id}
                        orderItem={orderItem}
                        openEditModal={openOrderItemEditModal}
                        itemEventListener={itemEventListener}
                        orderId={orderId}
                        queryClient={queryClient}
                      />
                    ))}
                    <Box textAlign="center" my={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Add />}
                        onClick={openModal}>
                        Add Order Items
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
            {/* add coments */}

            <Grid item lg={4} xs={12}>
              <Box>
                <Card
                  sx={{
                    minHeight: 350,
                    border: '1px solid #d9d9d9',
                    borderRadius: '5px',
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}>
                  <CardContent
                    sx={{
                      flexGrow: 1, // Remove overflowY and maxHeight here
                    }}>
                    {/* Comments section */}
                    <Box>
                      <Typography variant="h6">Comments</Typography>
                      <Box
                        sx={{
                          mt: 2,
                          maxHeight: '200px', // Keeping maxHeight here
                          overflowY: 'auto', // Keep scrollbar in this Box
                        }}>
                        {/* Displaying Comments */}
                        {comments?.data?.length ? (
                          comments.data.map((comment, index) => {
                            const user = users.data?.find(
                              u => u.id === comment.user
                            );

                            return (
                              <Box
                                key={index}
                                sx={{
                                  mb: 1,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}>
                                <Box
                                  sx={{
                                    mb: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <Avatar
                                    sx={{
                                      textTransform: 'capitalize',
                                    }}>
                                    {user &&
                                      user.username &&
                                      user.username[0].toUpperCase()}
                                  </Avatar>
                                  <Box>
                                    <Box
                                      sx={{
                                        mb: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}>
                                      <Typography
                                        variant="body2"
                                        color={'text.secondary'}
                                        sx={{
                                          textTransform: 'capitalize',
                                          marginLeft: 1,
                                        }}>
                                        {user?.username}
                                      </Typography>
                                      <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                        color={'text.secondary'}
                                        sx={{
                                          marginLeft: 1,
                                        }}>
                                        {formatTimeAgo(comment.created_at)}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        textTransform: 'capitalize',
                                        marginLeft: 1,
                                      }}>
                                      {comment.comment}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box>
                                  <IconButton
                                    onClick={() =>
                                      toggleImportantMutation.mutate({
                                        id: comment.id,
                                        status: comment.isImportant,
                                      })
                                    }>
                                    {comment.isImportant ? (
                                      <Tooltip title="Important">
                                        <StarRateIcon />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="UnImportant">
                                        <StarRateOutlinedIcon />
                                      </Tooltip>
                                    )}
                                  </IconButton>
                                </Box>
                              </Box>
                            );
                          })
                        ) : (
                          <Box textAlign="center" mt={5}>
                            <Typography variant="h6">
                              No comments yet.
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </CardContent>

                  {/* Add Comment Section */}
                  <form onSubmit={onCommentSubmit}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 2,
                        borderTop: '1px solid #d9d9d9',
                      }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Add a comment"
                        value={newComment}
                        onChange={e => setNewComment(e.target.value)}
                      />
                      <Button type="submit" variant="contained" sx={{ ml: 2 }}>
                        Add
                      </Button>
                    </Box>
                  </form>
                </Card>

                {/* logs show */}
                <Box
                  sx={{
                    mt: 2,
                    flexShrink: 0,
                    alignSelf: { lg: 'flex-end' },
                  }}>
                  <Logs orderId={orderId} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <CreateEditOrder
            open={orderEditModal.modalState}
            order={data}
            handleClose={closeOrderEditModal}
          />
          <Dialog
            open={orderImporantCommentModal.modalState}
            onClose={closeCommetModal}
            maxWidth="sm"
            fullWidth>
            <Importantcomments
              data={importantComments}
              handleClose={closeCommetModal}
              users={users.data}
            />
          </Dialog>
        </Box>
      )}
      {isEditMode && (
        <Modal
          open={modalState}
          onClose={closeModal}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CreateItem
            orderItem={selectedOrderItem}
            closeModal={closeModal}
            orderId={orderId}
            itemEventListener={itemEventListener}
          />
        </Modal>
      )}
    </Box>
  );
};

export default NewOrder;
