import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
import { useMessage } from '../../components/Header';
import SearchBar from '../../components/SearchBar';
import { useSettings } from '../../hooks/Authorize';
import useErrorHandler from '../../hooks/useErrorHandler';
import useModal from '../../hooks/useModal';
import usePagination from '../../hooks/usePagination';
import { server } from '../../utils/axios';
import { CreateEditOrder } from './CreateEditOrder';

const Index = () => {
  const settings = useSettings();
  const orderStatuses = settings.getValue('order-statuses');
  const statusesAvailable = useMemo(
    () => orderStatuses.map(or => or) || [],
    [orderStatuses]
  );
  const queryClient = useQueryClient();
  const { paginationModel, setPaginationModel, offset, pageSize } =
    usePagination();

  const [searchParams, setSearchParams] = useSearchParams({
    search: '',
    status: '',
  });

  const updateSearchParams = name => e =>
    setSearchParams(prev => {
      if (e.target.value === '') prev.delete(name);
      else prev.set(name, e.target.value);
      return prev;
    });

  const search = searchParams.get('search');
  const status = searchParams.get('status');

  const [rowCount, setRowCount] = useState(0);
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();
  const { showSuccess, showError } = useMessage();
  const { closeModal, openModal, modalState } = useModal();

  const getOrders = async () => {
    const params = {
      search,
      status,
      limit: pageSize,
      offset: offset,
      expand: 'customer',
    };

    try {
      const response = await server.get(`/orders/`, { params });
      const orders = response.data;

      setRowCount(orders.count);
      return orders.results;
    } catch (e) {
      errorHandler(e);
    }
  };

  const {
    data = [],
    isPending,
    isFetching,
  } = useQuery({
    queryKey: ['orders', search, status, paginationModel],
    queryFn: getOrders,
  });

  const mutation = useMutation({
    mutationFn: async updateData => {
      const { id, status } = updateData;

      await server.patch(`/orders/${id}`, {
        status,
      });
    },
    onMutate: () => {
      queryClient.cancelQueries(['orders', search, paginationModel]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['orders', search, paginationModel]);

      showSuccess('Order status updated successfully');
    },
    onError: () => {
      showError('Failed to update status');
    },
  });

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: false,
      width: 100,
    },
    {
      field: 'shopify_id',
      headerName: 'Shopify Id',
      sortable: false,
      width: 200,
      valueGetter: params => params.row.shopify_id || 'N/A',
    },
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 1,
      width: 200,
      sortable: false,
      renderCell: params => (
        <Typography
          variant="subtitle2"
          fontWeight="600"
          sx={{ color: 'primary.main' }}>
          {params.row.customer_name}
        </Typography>
      ),
    },
    {
      field: 'financial_status',
      headerName: 'Financial Status',
      width: 200,
      sortable: false,
      renderCell: params => (
        <Typography
          variant="subtitle2"
          fontWeight="600"
          sx={{ color: 'primary.main' }}>
          {params.row.financial_status}
        </Typography>
      ),
    },
    {
      field: 'created_on',
      headerName: 'Created On',
      sortable: false,
      width: 200,
      valueGetter: params => new Date(params.row.created_on).toLocaleString(),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      renderCell: params => (
        <Select
          name="event"
          value={params.row.status || ''}
          onChange={e =>
            mutation.mutate({
              id: params.row.id,
              status: e.target.value,
            })
          }
          required
          sx={{
            width: {
              xs: '100%',
              sm: '90%',
              lg: '316px',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 300,
                overflow: 'auto',
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}>
          <MenuItem key="none" value="">
            Select Status
          </MenuItem>
          {statusesAvailable.map(status => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <Container maxWidth="false">
      <Box pt={3}>
        <Typography variant="h4" fontWeight={500}>
          Orders
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ wordSpacing: '2px' }}>
          Manage your orders from this page
        </Typography>
        <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
      </Box>
      <Box mb={3} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={searchParams.get('status') || ''}
          onChange={(_, newValue) =>
            setSearchParams(prev => prev.set('status', newValue))
          }>
          <Tab label="All" value="" />
          {statusesAvailable.map(status => (
            <Tab label={status} value={status} />
          ))}
        </Tabs>
      </Box>
      <DataGrid
        rows={data}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
        autoHeight
        onRowClick={data => navigate(`/orders/edit/${data.row.id}/`)}
        disableSelectionOnClick
        loading={isPending}
        rowCount={rowCount}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            openModal,
            statusesAvailable,
            searchParams,
            updateSearchParams,
            isFetching,
          },
        }}
        checkboxSelection={false}
      />

      <CreateEditOrder
        handleOpen={openModal}
        handleClose={closeModal}
        open={modalState}
      />
    </Container>
  );
};

export function CustomToolbar({
  openModal,
  statusesAvailable,
  searchParams,
  updateSearchParams,
  isFetching,
}) {
  return (
    <>
      <GridToolbarContainer sx={{ pb: '24px' }}>
        <Grid container alignItems="center" width="100%" spacing={1}>
          <Grid item xs>
            <SearchBar
              value={searchParams.get('search')}
              onChange={updateSearchParams('search')}
            />
          </Grid>
          <Grid item>
            <Select
              value={searchParams.get('status') || ''}
              displayEmpty
              onChange={updateSearchParams('status')}
              required
              sx={{
                width: {
                  xs: '100%',
                  sm: '90%',
                  lg: '316px',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 300,
                    overflow: 'auto',
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}>
              <MenuItem value="">All</MenuItem>
              {statusesAvailable.map(status => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={openModal}>
              Create Order
            </Button>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </GridToolbarContainer>
      <LinearProgress sx={{ visibility: isFetching ? 'visible' : 'hidden' }} />
    </>
  );
}

export default Index;
