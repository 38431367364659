export function env(name) {
    const nodeENV = process.env.NODE_ENV.toUpperCase();
    console.log(nodeENV);

    return (
        process.env[`REACT_APP_${nodeENV}_${name}`] ||
        process.env[`REACT_APP_${name}`]
    );
}

export const getRandomHash = (digits = 6) =>
    Math.floor(Math.random() * Math.pow(10, digits))
        .toString()
        .padStart(digits, '0');

export const getSession = name => sessionStorage[name];

export const setSession = (name, value) => (sessionStorage[name] = value);

export const deleteSession = name => sessionStorage.removeItem(name);

export const isDefined = v => typeof v !== 'undefined';

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function escapeDanger(content) {
    const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gim;

    if (regex.test(content)) return null;
    return { __html: content };
}

export const formatTimeAgo = date => {
    const now = new Date();
    const seconds = Math.floor((now - new Date(date)) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) return `${interval} years ago`;
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} months ago`;
    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} days ago`;
    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} hours ago`;
    interval = Math.floor(seconds / 60);
    if (interval > 1) return `${interval} minutes ago`;
    return `${seconds} seconds ago`;
};
