import Webhook from '@mui/icons-material/Webhook';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TocIcon from '@mui/icons-material/Toc';
import SettingsIcon from '@mui/icons-material/Settings';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import FilterListIcon from '@mui/icons-material/FilterList';

const getMenuItems = settings => {
  const orderStatuses = settings.getValue('order-statuses' || []);

  return [
    {
      name: 'Products',
      icon: <CategoryIcon fontSize="small" />,
      to: '/products',
    },
    {
      name: 'Orders',
      icon: <ShoppingCartIcon fontSize="small" />,
      to: [
        {
          name: 'All',
          icon: <FilterListIcon fontSize="small" />,
          to: '/orders',
          value: '',
        },
        ...orderStatuses
          .map(status => status.trim()) // Trim whitespace
          .filter(status => status) // Ensure status is not empty
          .map(status => ({
            name: status[0].toUpperCase() + status.slice(1),
            icon: <FilterListIcon fontSize="small" />,
            to: '/orders?status=' + encodeURIComponent(status),
            value: status,
          })),
      ],
    },
    {
      name: 'Logs',
      icon: <TocIcon fontSize="small" />,
      to: '/logs',
    },
    {
      name: 'Webhooks',
      icon: <Webhook fontSize="small" />,
      to: '/webhooks',
    },
    {
      name: 'Scripts',
      icon: <DataObjectOutlinedIcon fontSize="small" />,
      to: '/scripts',
    },
    {
      name: 'Triggers',
      icon: <FlashAutoIcon fontSize="small" />,
      to: '/triggers',
    },
    {
      name: 'Cron',
      icon: <FlashAutoIcon fontSize="small" />,
      to: '/cron',
    },
    {
      name: 'Settings',
      icon: <SettingsIcon fontSize="small" />,
      to: '/settings',
    },
  ];
};

// Uncomment if you want to add the Master Data menu
// {
//   name: 'Master Data',
//   icon: <InsightsOutlinedIcon fontSize='small' />,
//   to: [
//     {
//       name: 'Goal',
//       icon: <FlagCircleOutlinedIcon fontSize='small' />,
//       to: '/master/goal',
//     },
//   ],
// },

export default getMenuItems;
