import Editor from '@monaco-editor/react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Button,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessage } from '../../components/Header';
import Loading from '../../components/Loading';
import useErrorHandler from '../../hooks/useErrorHandler';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import useLoader from '../../hooks/useLoader';
import useModal from '../../hooks/useModal';
import { server } from '../../utils/axios';
import DeleteModal from './DeleteModal';

const EditCreateScript = () => {
    const { scriptId } = useParams(); // Assume scriptId for editing
    const isEditMode = Boolean(scriptId);
    const { showSuccess } = useMessage();
    const { startLoading, endLoading, loaderState } = useLoader();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const deleteModal = useModal();

    const handlers = useForm(
        useMemo(
            () => ({
                name: { required: true },
                description: {},
                code: {},
            }),
            []
        ),
        { Input: TextField }
    );

    const setScriptValues = handlers.setValues;

    const codeChangeHandler = value => setScriptValues({ code: value });

    const getScript = useCallback(async () => {
        if (!isEditMode) return;

        startLoading();
        try {
            const response = await server.get(`/scripts/${scriptId}/`);
            const { name, code, description } = response.data;
            setScriptValues({
                name,
                code,
                description,
            });
        } catch (e) {
            errorHandler(e);
        } finally {
            endLoading();
        }
    }, [
        scriptId,
        setScriptValues,
        startLoading,
        endLoading,
        errorHandler,
        isEditMode,
    ]);

    const onSubmit = () => showSuccess('Script saved successfully');

    useEffect(() => {
        if (isEditMode) {
            getScript();
        }
    }, [isEditMode, getScript]);

    return (
        <Box
            bgcolor="background.paper"
            height="calc(100vh - 76px)"
            overflow="auto"
            display="flex"
            flexDirection="column"
            p={0}>
            <Grid container alignItems="center" sx={{ pr: 2 }}>
                <Grid item xs>
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        color="primary"
                        p={2}>
                        {isEditMode ? `Edit Script` : `New Script`}
                    </Typography>
                </Grid>
                {isEditMode && (
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={deleteModal.openModal}
                            endIcon={<DeleteIcon />}>
                            Delete
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Divider variant="fullWidth" />
            {loaderState ? (
                <Loading message="Please wait, while your data is loading..." />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={isEditMode ? `/scripts/${scriptId}/` : '/scripts/'}
                    method={isEditMode ? 'patch' : 'post'}
                    axiosInstance={server}
                    retainOnSubmit
                    onError={errorHandler}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display="flex"
                        flexDirection="column"
                        mb={4}
                        overflow="auto">
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems="center"
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant="body2" fontWeight={600}>
                                    Name
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10} display="flex">
                                <Input
                                    name="name"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* Description Field */}
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems="center"
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant="body2" fontWeight={600}>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10} display="flex">
                                <Input
                                    name="description"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant="fullWidth" sx={{ mt: 2 }} />
                    </Box>
                    <Grid container mb={2}>
                        <Grid item xs={12} sx={{ mb: 2, px: 2 }}>
                            <Typography variant="body2" fontWeight={600}>
                                Code
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                height="90vh"
                                defaultLanguage="javascript"
                                defaultValue="// Write your script here"
                                value={handlers.values.code}
                                onChange={codeChangeHandler}
                            />
                        </Grid>
                    </Grid>
                    <Stack
                        direction="row"
                        p={2}
                        spacing={2}
                        sx={{
                            boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                            backgroundColor: 'background.paper',
                            zIndex: 1100,
                            position: 'fixed',
                            bottom: 0,
                            width: {
                                xs: '100%',
                                xm: `calc(100% - 280px)`,
                            },
                        }}>
                        <Submit>
                            {loader => (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    endIcon={loader}>
                                    Save
                                </Button>
                            )}
                        </Submit>
                        <Button
                            onClick={() => navigate(-1)}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Form>
            )}
            {isEditMode && (
                <DeleteModal modal={deleteModal} scriptId={scriptId} />
            )}
        </Box>
    );
};

export default EditCreateScript;
