import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

const Loading = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                color: 'black', // Add this line to set the text color
            }}>
            <CircularProgress size={80} />
            <Typography variant="h6" sx={{ marginTop: 2, color: 'black' }}>
                Loading Admin Interface...
            </Typography>
        </Box>
    );
};

export default Loading;
