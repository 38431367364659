import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/FileUpload';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import ActionIcon from '../../components/ActionIcon';
import { useMessage } from '../../components/Header';
import Image from '../../components/Image';
import useModal from '../../hooks/useModal';
import useErrorHandler from '../../hooks/useErrorHandler';
import { server } from '../../utils/axios';
import { env, getRandomHash } from '../../utils/function';
import { useQueryClient } from '@tanstack/react-query';

const regenerateContent = {
  title: 'Regenerate the SVG?',
  body: 'The script has been updated after the last SVG was generated. Do you want to regenerate the SVG?',
};

const uploadedContent = {
  title: 'Regenerate SVG file?',
  body: 'The SVG file was uploaded manually. Do you want to regenerate the SVG file?',
};

const OrderItem = props => {
  const { orderItem, openEditModal, itemEventListener } = props;
  const {
    id: orderItemId,
    product,
    more_attributes,
    svg_name,
    orderId,
  } = orderItem;
  const errorHandler = useErrorHandler();
  const [downloading, setDownloading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [uploading, setUploading] = useState(false);
  const confirmationModal = useModal();
  const { showSuccess, showError } = useMessage();
  const uploadInput = useRef();
  const serverUri = env('SERVER');
  const [hash, setHash] = useState(getRandomHash);
  const [confirmationModalContent, setConfirmationModalContent] = useState({});
  const queryClient = useQueryClient();

  const updateHash = () => setHash(getRandomHash());

  const selectFile = () => uploadInput.current.click();

  const handleFileChange = e => upload(e.target.files[0]);

  const download = async function () {
    setDownloading(true);
    try {
      const response = await server.get('/download/' + orderItemId, {
        responseType: 'blob',
      });

      const dispo = response.headers['content-disposition'];

      const fileName = dispo
        .split(';')
        .find(el => el.split('=')[0].trim() === 'filename')
        .split('=')[1]
        .slice(1, -1);

      console.log(fileName);

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      errorHandler(error, {
        404: 'SVG file not found',
      });
      console.error('Error downloading file:', error);
    }
    setDownloading(false);
  };

  const confirmGenerateSVG = () => {
    if (orderItem.svg_mode === 'uploaded') {
      setConfirmationModalContent(uploadedContent);
      return confirmationModal.openModal();
    }

    if (
      new Date(orderItem.svg_generated_time) < new Date(product.script_updated)
    ) {
      setConfirmationModalContent(regenerateContent);
      return confirmationModal.openModal();
    }

    generateSVG();
  };

  const generateSVG = async function () {
    setGenerating(true);
    try {
      const response = await server.post(`/generate-svg/${orderItemId}`);
      const svgName = response.data.svg_name;
      showSuccess('SVG generated successfully');

      queryClient.invalidateQueries('order');
      itemEventListener.emit('edit', {
        ...orderItem,
        svg_mode: 'generated',
        svg_name: svgName,
      });

      updateHash();
    } catch (error) {
      showError('Error generating SVG');
    } finally {
      setGenerating(false);
      confirmationModal.closeModal();
    }
  };

  const upload = async function (file) {
    setUploading(true);
    try {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('orderItem', orderItemId);

      const response = await server.post('/upload-svg/', formData);
      const new_svg_name = response.data.file_name;

      queryClient.invalidateQueries('order');
      itemEventListener.emit('edit', {
        ...orderItem,
        svg_mode: 'uploaded',
        svg_name: new_svg_name,
      });

      updateHash();

      showSuccess('File uploaded successfully');
    } catch (error) {
      console.error('Error downloading file:', error);
    }
    setUploading(false);
  };

  const sendToLaser = async (laser, order_item) => {
    try {
      await server.post('/logs/laser-queue/', { laser, order_item });
      showSuccess(`Order sent to laser ${laser} successfully`);
      queryClient.invalidateQueries(['logs', orderId]);
    } catch (error) {
      errorHandler(error);
    }
  };

  const preview = () => {
    // Open a new tab with the SVG preview
    if (!svg_name) return showError('No SVG preview available');

    window.open(`${serverUri}/svg-generated/${svg_name}`, '_blank');
  };

  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 2,
        border: '1px solid #d9d9d9',
        borderRadius: '5px',
        boxShadow: 'none',
      }}>
      <Box p={3}>
        <Grid container alignItems="start" spacing={3}>
          <Grid item xs>
            <Stack>
              <Typography variant="h5">{product.name}</Typography>
              <Grid
                container
                columnSpacing={5}
                rowSpacing={1}
                maxWidth="500px"
                alignItems="center"
                mt={3}>
                {Object.keys(more_attributes).map(attr => (
                  <React.Fragment key={attr}>
                    <Grid item display="flex" gap={2} xs={12} sm={6}>
                      <Typography variant="body2" fontWeight={600}>
                        {attr}
                      </Typography>
                      <Typography variant="body2">
                        {more_attributes[attr]}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Stack>
          </Grid>
          <Grid item>
            {svg_name && (
              <Image
                onClick={preview}
                height="150px"
                src={`${serverUri}/svg-generated/${svg_name}?random-hash=${hash}`}
                alt="SVG Not Available"
              />
            )}
          </Grid>
          <Grid item>
            <ActionIcon
              title="Preview"
              onClick={preview}
              tooltipProps={{ placement: 'left' }}>
              <VisibilityIcon fontSize="small" sx={{ color: 'primary.main' }} />
            </ActionIcon>
            <br />
            <ActionIcon
              title="Edit"
              onClick={() => openEditModal(orderItem)}
              tooltipProps={{ placement: 'left' }}>
              <EditIcon fontSize="small" sx={{ color: 'primary.main' }} />
            </ActionIcon>
            <br />
            <ActionIcon
              title="Download SVG"
              onClick={download}
              tooltipProps={{ placement: 'left' }}>
              {downloading ? (
                <CircularProgress size="18px" />
              ) : (
                <DownloadIcon fontSize="small" sx={{ color: 'primary.main' }} />
              )}
            </ActionIcon>
            <br />
            <ActionIcon
              title="Generate SVG"
              onClick={confirmGenerateSVG}
              tooltipProps={{ placement: 'left' }}>
              {generating ? (
                <CircularProgress size="18px" />
              ) : (
                <AutoFixHighIcon
                  fontSize="small"
                  sx={{ color: 'primary.main' }}
                />
              )}
            </ActionIcon>
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor="#f8f9fb" py={1.5} px={2}>
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent="space-between"
          gap={1}
          alignItems="center">
          <Typography variant="body2" color="text.secondary">
            Send SVG file to laser machine for precise cutting and engraving.
          </Typography>
          <Stack
            direction={'row'}
            flexWrap={'wrap'}
            justifyContent="flex-end"
            gap={1}
            alignItems="center">
            <Button
              variant="outlined"
              size="small"
              sx={{ fontSize: 12 }}
              startIcon={<PrecisionManufacturingIcon />}
              onClick={e => {
                e.stopPropagation();
                sendToLaser(1, orderItemId);
              }}>
              Send to laser 1
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ fontSize: 12 }}
              startIcon={<PrecisionManufacturingIcon />}
              onClick={e => {
                e.stopPropagation();
                sendToLaser(2, orderItemId);
              }}>
              Send to laser 2
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ fontSize: 12 }}
              disabled={uploading}
              startIcon={
                uploading ? <CircularProgress size="18px" /> : <UploadIcon />
              }
              onClick={selectFile}>
              Upload SVG
            </Button>
          </Stack>
        </Stack>
      </Box>
      <input
        type="file"
        onChange={handleFileChange}
        ref={uploadInput}
        style={{ display: 'none' }}
      />
      <Modal
        open={confirmationModal.modalState}
        onClose={confirmationModal.closeModal}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Card
          sx={{
            boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
            borderRadius: '18px',
            overflowY: 'auto',
            maxHeight: '80vh',
            maxWidth: '600px',
            width: '100%',
            mx: 2,
            '::-webkit-scrollbar': { display: 'none' },
            p: 2,
          }}>
          <Box
            sx={{
              p: 3,
              color: 'text.secondary',
              fontWeight: 500,
              letterSpacing: '1.2px',
              fontSize: '14px',
            }}>
            <Typography variant="h5" fontWeight={600} color="primary">
              {confirmationModalContent?.title}
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Typography
              variant="subtitle2"
              fontWeight={600}
              color="primary"
              mb={5}>
              {confirmationModalContent?.body}
            </Typography>

            <Box textAlign="right" direction="row" justifyContent="end" mt={2}>
              <Button
                disabled={generating}
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={confirmationModal.closeModal}>
                Cancel
              </Button>
              <Button
                disabled={generating}
                color="primary"
                variant="contained"
                onClick={generateSVG}
                endIcon={
                  generating ? (
                    <CircularProgress size="18px" />
                  ) : (
                    <AutoFixHighIcon />
                  )
                }>
                Generate
              </Button>
            </Box>
          </Box>
        </Card>
      </Modal>
    </Card>
  );
};

export default OrderItem;
