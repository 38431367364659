import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
// import AutoModeIcon from '@mui/icons-material/AutoMode';
// import EditIcon from '@mui/icons-material/Edit';
// import ActionIcon from '../../components/ActionIcon';
import useErrorHandler from '../../hooks/useErrorHandler';
import useLoader from '../../hooks/useLoader';
import { server } from '../../utils/axios';

const columns = [
  //   {
  //     headerName: 'Action',
  //     sortable: false,
  //     width: 250,
  //     renderCell: params => (
  //       <>
  //         <ActionIcon
  //           LinkComponent={NavLink}
  //           to={`/scripts/edit/${params.row.name}/`}
  //           title="Edit">
  //           <EditIcon />
  //         </ActionIcon>
  //         <ActionIcon
  //           LinkComponent={NavLink}
  //           to={`/scripts/executions/${params.row.name}/`}
  //           title="Executions">
  //           <AutoModeIcon />
  //         </ActionIcon>
  //       </>
  //     ),
  //   },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 1,
    renderCell: params => (
      <Typography
        variant="subtitle2"
        fontWeight="600"
        sx={{ color: 'primary.main' }}>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'schedule',
    headerName: 'Schedule',
    sortable: false,
    width: 200,
    renderCell: params => (
      <Typography variant="body2">{params.row.schedule}</Typography>
    ),
  },
  {
    field: 'script',
    headerName: 'Script',
    sortable: false,
    flex: 1,
    renderCell: params => (
      <Typography variant="body2">{params.row.script}</Typography>
    ),
  },
];

const Index = () => {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const { startLoading: start, endLoading: end, loaderState } = useLoader();
  const errorHandler = useErrorHandler();

  const getScripts = useCallback(async () => {
    start();
    setRows([]);

    const params = {
      limit: paginationModel.pageSize,
      offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
    };

    if (search) params.search = search;

    try {
      // Fetch data from the cronscripts API
      const cronResponse = await server.get(`/scripts/cronscripts/`);
      const cronScripts = cronResponse.data.results;

      // Combine both scripts and cron scripts
      const combinedScripts = [...cronScripts];

      setRows(combinedScripts);
      setRowCount(cronScripts.length);
    } catch (e) {
      errorHandler(e);
    } finally {
      end();
    }
  }, [paginationModel, search, start, end, errorHandler]);

  useEffect(() => {
    getScripts();
  }, [getScripts]);

  return (
    <Container maxWidth="false">
      <Box pt={3}>
        <Typography variant="h4" fontWeight={500}>
          Cron Scripts
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ wordSpacing: '2px' }}>
          Manage your scripts from this page
        </Typography>
        <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
      </Box>
      <DataGrid
        getRowId={row => row.name}
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
        autoHeight
        disableSelectionOnClick
        loading={loaderState}
        rowCount={rowCount}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: { setSearch, search },
        }}
        checkboxSelection={false}
      />
    </Container>
  );
};

export function CustomToolbar({ setSearch, search }) {
  const navigate = useNavigate();

  return (
    <GridToolbarContainer sx={{ pb: '24px' }}>
      <Grid container alignItems="center" width="100%" spacing={1}>
        <Grid item xs></Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate('new')}>
            Create Cron Script
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default Index;
