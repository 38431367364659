import {
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Box,
    Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { formatTimeAgo } from '../../utils/function';

const ImportantComments = ({ handleClose, data, users }) => {
    return (
        <>
            <DialogTitle>
                Important Comments
                {/* Close icon at the top-right */}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {/* Check if data is available and map through it */}
                {data && data.length > 0 ? (
                    data.map((comment, index) => {
                        const user = users.find(u => u.id === comment.user); // Match the user
                        return (
                            <Box
                                key={index}
                                sx={{
                                    mb: 1,
                                    display: 'flex',

                                    justifyContent: 'space-between',
                                }}>
                                <Box
                                    sx={{
                                        mb: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <Avatar
                                        sx={{
                                            textTransform: 'capitalize',
                                        }}>
                                        {user &&
                                            user.username &&
                                            user.username[0].toUpperCase()}
                                    </Avatar>
                                    <Box>
                                        <Box
                                            sx={{
                                                mb: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <Typography
                                                variant="body2"
                                                color={'text.secondary'}
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    marginLeft: 1,
                                                }}>
                                                {user?.username}
                                            </Typography>
                                            <Typography
                                                fontWeight="bold"
                                                variant="body2"
                                                color={'text.secondary'}
                                                sx={{
                                                    marginLeft: 1,
                                                }}>
                                                {formatTimeAgo(
                                                    comment.created_at
                                                )}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                textTransform: 'capitalize',
                                                marginLeft: 1,
                                            }}>
                                            {comment.comment}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })
                ) : (
                    <Typography variant="body1">
                        No important comments available.
                    </Typography>
                )}
            </DialogContent>
        </>
    );
};

export default ImportantComments;
