import { Stack, Modal, Card, TextField, MenuItem } from '@mui/material';
import { Box, Button, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useMutation, useQuery } from '@tanstack/react-query';
import useErrorHandler from '../../hooks/useErrorHandler';
import { server } from '../../utils/axios';
import { useMessage } from '../../components/Header';

const fieldTypes = [
  { value: 'text', label: 'Text' },
  { value: 'number', label: 'Number' },
  { value: 'date', label: 'Date' },
];

export default function CreateGenerator(props) {
  const { modal, productId } = props;
  const { modalState, closeModal } = modal;
  const errorHandler = useErrorHandler();
  const { showSuccess } = useMessage();

  const [fields, setFields] = useState([{ name: '', type: 'text' }]);
  const [existingFields, setExistingFields] = useState([]); // To track existing fields
  const [generatorId, setGeneratorId] = useState();

  // Fetch the list of generators
  const { data: generators, isLoading } = useQuery({
    queryKey: ['generators'],
    queryFn: async () => {
      const response = await server.get('/generator/generatorField/');
      return response.data.results;
    },
  });

  const handleFieldChange = (index, key, value) => {
    const newFields = [...fields];
    newFields[index][key] = value;
    setFields(newFields);
  };

  const addField = () => {
    setFields([...fields, { name: '', type: 'text' }]);
  };

  const handleImageChange = event => {
    const file = event.target.files[0];

    // Immediately upload the image
    const formData = new FormData();
    formData.append('image', file);
    formData.append('product', productId);

    uploadImage.mutate(formData);
  };

  const uploadImage = useMutation({
    mutationFn: async formData => {
      const response = await server.post(`generator/generatorField/`, formData);
      return setGeneratorId(response.data.id);
    },
    onSuccess: () => {
      showSuccess('Image uploaded successfully');
    },
    onError: error => {
      errorHandler(error);
    },
  });

  // Mutation to submit field data
  const createGenerator = useMutation({
    mutationFn: async data => {
      return await server.patch(
        `generator/generatorField/${generatorId}/`,
        data
      );
    },
    onSuccess: () => {
      showSuccess('Generator fields updated successfully');
      closeModal();
    },
    onError: error => {
      errorHandler(error);
    },
  });

  const handleSubmit = () => {
    // Prepare an array to hold new or modified fields
    const updatedFields = fields.filter((field, index) => {
      const existingField = existingFields[index];
      return (
        !existingField ||
        existingField.field_name !== field.name ||
        existingField.field_type !== field.type
      );
    });

    const fieldData = {
      product: productId,
      fields: updatedFields.map(field => ({
        field_name: field.name,
        field_type: field.type,
      })),
    };

    // Only send if there are changes
    if (updatedFields.length > 0) {
      createGenerator.mutate(fieldData); // Trigger the mutation
    } else {
      showSuccess('No changes to update');
    }
  };

  useEffect(() => {
    if (generators) {
      const foundGenerator = generators.find(
        generator => generator.product === parseInt(productId)
      );

      if (foundGenerator) {
        setGeneratorId(foundGenerator.id);
        setExistingFields(foundGenerator.fields); // Set existing fields
        setFields(
          foundGenerator.fields.map(field => ({
            name: field.field_name,
            type: field.field_type,
          }))
        ); // Set fields for editing
      }
    }
  }, [generators, productId]);

  if (isLoading) {
    return <Typography>Loading...</Typography>; // Optional loading state
  }

  return (
    <Modal
      open={modalState}
      onClose={closeModal}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Card
        sx={{
          boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
          borderRadius: '18px',
          overflowY: 'auto',
          maxHeight: '80vh',
          maxWidth: '600px',
          width: '100%',
          mx: 2,
          '::-webkit-scrollbar': { display: 'none' },
          p: 2,
        }}>
        <Box
          sx={{
            p: 3,
            color: 'text.secondary',
            fontWeight: 500,
            letterSpacing: '1.2px',
            fontSize: '14px',
          }}>
          <Typography variant="h5" fontWeight={600} color="primary" mb={2}>
            Create Generator
          </Typography>
          <TextField
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          {/* Fields input section */}
          {fields.map((field, index) => (
            <Stack direction="row" spacing={2} key={index} mb={2}>
              <TextField
                placeholder={`Field Name`}
                variant="outlined"
                value={field.name}
                onChange={e => handleFieldChange(index, 'name', e.target.value)}
                fullWidth
              />
              <TextField
                label="Field Type"
                variant="outlined"
                select
                value={field.type}
                onChange={e => handleFieldChange(index, 'type', e.target.value)}
                fullWidth>
                {fieldTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          ))}

          {/* Add Field button */}
          <Box textAlign="center" mb={2}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={addField}>
              Add Field
            </Button>
          </Box>

          {/* Action buttons */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Box textAlign="center">
              <Button
                variant="outlined"
                sx={{
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  padding: '12px 28px',
                }}
                onClick={closeModal}>
                Cancel
              </Button>
            </Box>
            <Box textAlign="center">
              <Button
                color="error"
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  padding: '12px 28px',
                }}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Box>
      </Card>
    </Modal>
  );
}
