import { useMemo } from 'react';
import { useState } from 'react';

const usePagination = (initialPage = 1, initalPageSize = 20) => {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [pageSize, setPageSize] = useState(initalPageSize);
    const paginationModel = useMemo(
        () => ({
            pageSize,
            page: currentPage - 1,
        }),
        [pageSize, currentPage]
    );

    const offset = useMemo(() => {
        return (currentPage - 1) * pageSize;
    }, [currentPage, pageSize]);

    const goToPage = page => {
        setCurrentPage(page);
    };

    const nextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const setPaginationModel = paginationModel => {
        setCurrentPage(paginationModel.page + 1);
        setPageSize(paginationModel.pageSize);
    };

    return {
        pageSize,
        currentPage,
        goToPage,
        nextPage,
        prevPage,
        offset,
        setPageSize,
        paginationModel,
        setPaginationModel,
    };
};

export default usePagination;
