import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Box,
  Typography,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
  Stack,
} from '@mui/material';

const ModalForm = ({ open, onClose, onSave, item, isEditMode }) => {
  const [formData, setFormData] = useState(item);

  const handleChange = (key, value) => {
    setFormData(prev => ({ ...prev, [key]: value }));
  };

  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  useEffect(() => {
    setFormData(item);
  }, [item]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          p: 4,
          boxShadow: 24,
          borderRadius: 1,
        }}>
        <Typography variant="h6" mb={2}>
          {isEditMode ? 'Edit Item' : 'Add Item'}
        </Typography>
        <TextField
          fullWidth
          placeholder="Name"
          variant="outlined"
          value={formData.name}
          onChange={e => handleChange('name', e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          fullWidth
          control={
            <Switch
              checked={formData.enabled}
              onChange={e => handleChange('enabled', e.target.checked)}
            />
          }
          label="Enabled"
        />
        <br />
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          onClick={handleSave}>
          {isEditMode ? 'Save Changes' : 'Add Item'}
        </Button>
      </Box>
    </Modal>
  );
};

const ObjectEditor = ({ title, subtitle, value = [], onSave }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [currentItem, setCurrentItem] = useState({ name: '', enabled: false });
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleOpenModal = (
    item = { name: '', enabled: false },
    index = null
  ) => {
    setCurrentItem(item);
    setCurrentIndex(index);
    setEditMode(index !== null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentItem({ name: '', enabled: false });
    setCurrentIndex(null);
  };

  const handleSave = updatedItem => {
    const updatedData = [...value];
    if (isEditMode && currentIndex !== null) {
      updatedData[currentIndex] = updatedItem;
    } else {
      updatedData.push(updatedItem);
    }
    onSave(updatedData);
  };

  const handleDeleteSetting = index => {
    const updatedData = value.filter((_, i) => i !== index);
    onSave(updatedData);
  };

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}>
        <Box>
          <Typography
            textTransform="capitalize"
            variant="body1"
            fontWeight={600}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ my: 0.4 }}>
            {subtitle}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal()}>
          Add Item
        </Button>
      </Stack>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Enabled</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography>{item.name}</Typography>
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={<Switch checked={item.enabled} />}
                    label=""
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(item, index)}>
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ ml: 2 }}
                    onClick={() => handleDeleteSetting(index)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Add/Edit */}
      <ModalForm
        open={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSave}
        item={currentItem}
        isEditMode={isEditMode}
      />
    </Box>
  );
};

export default ObjectEditor;
