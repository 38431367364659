import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './utils/axios';
import AuthorizeRouter from './router/AuthorizeRouter';
import Header from './components/Header';
import Products from './pages/Products';
import CreateProducts from './pages/Products/Create';
import EditCreateScript from './pages/Scripts/EditCreate';
import ListExecutions from './pages/Scripts/ListExecutions';
import Orders from './pages/Orders';
import ViewOrder from './pages/Orders/ViewOrder';
import Logs from './pages/Logs';
import Webhooks from './pages/Webhooks';
import Settings from './pages/Settings';
import Scripts from './pages/Scripts';
import Triggers from './pages/Triggers';
import EditCreateTriggers from './pages/Triggers/EditCreate';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Cron from './pages/Cron';
import EditCreateCronScript from './pages/Cron/EditCreate';

export const queryClient = new QueryClient();

const App = () => {
  return (
    <Header>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Routes>
          <Route path="/" element={<AuthorizeRouter />}>
            <Route path="/" element={<Navigate to="/orders" />} />
            <Route path="/products">
              <Route path="" element={<Products />} />
              <Route path="new" element={<CreateProducts />} />
              <Route path="edit/:productId" element={<CreateProducts />} />
            </Route>
            <Route path="/orders">
              <Route path="" element={<Orders />} />
              <Route path="new" element={<ViewOrder />} />
              <Route path="edit/:orderId" element={<ViewOrder />} />
            </Route>
            <Route path="/logs" element={<Logs />} />
            <Route path="/webhooks" element={<Webhooks />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/scripts">
              <Route path="" element={<Scripts />} />
              <Route path="new" element={<EditCreateScript />} />
              <Route path="edit/:scriptId" element={<EditCreateScript />} />
              <Route
                path="executions/:scriptName"
                element={<ListExecutions />}
              />
            </Route>
            <Route path="/triggers">
              <Route path="" element={<Triggers />} />
              <Route path="new" element={<EditCreateTriggers />} />
              <Route path="edit/:trigersId" element={<EditCreateTriggers />} />
            </Route>
            <Route path="/cron">
              <Route path="" element={<Cron />} />
              <Route path="new" element={<EditCreateCronScript />} />
              <Route
                path="edit/:trigersId"
                element={<EditCreateCronScript />}
              />
            </Route>
          </Route>
        </Routes>
      </QueryClientProvider>
    </Header>
  );
};

export default App;
