import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { Form, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs';
import { server } from '../../utils/axios';

const financialStatuses = ['Paid', 'Unpaid'];

export function CreateEditOrder({ handleClose, open, order }) {
  const queryClient = useQueryClient();
  const editMode = Boolean(order);

  // Form Data States
  const handlers = useForm(
    useMemo(
      () => ({
        customer_name: { required: true },
        customer_email: { required: true },
        address1: '',
        address2: '',
        city: '',
        country: '',
        phone: '',
        zip: '',
        company: '',
        financial_status: { value: 'Unpaid' },
        totalPrice: { value: '0' },
        note: '',
      }),
      []
    ),
    { Input: TextField }
  );

  const setOrderValues = handlers.setValues;

  const onSubmit = async () => {
    handleClose();
    const queryKey = editMode ? 'order' : 'orders';
    queryClient.invalidateQueries(queryKey);
  };

  const handleSelectChange = event => {
    const { name, value } = event.target;
    setOrderValues({ [name]: value });
  };

  function modifyDataForApi(values) {
    return {
      customer_name: values.customer_name,
      customer_email: values.customer_email,
      financial_status: values.financial_status,
      total_price: values.totalPrice,
      note: values.note,
      shipping_address: {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        country: values.country,
        phone: values.phone,
        zip: values.zip,
        company: values.company,
      },
    };
  }

  useEffect(() => {
    if (!editMode) return;
    setOrderValues({
      customer_name: order.customer_name,
      customer_email: order.customer_email,
      address1: order.shipping_address?.address1 || '',
      address2: order.shipping_address?.address2 || '',
      city: order.shipping_address?.city || '',
      country: order.shipping_address?.country || '',
      phone: order.shipping_address?.phone || '',
      zip: order.shipping_address?.zip || '',
      company: order.shipping_address?.company || '',
      financial_status: order.financial_status,
      totalPrice: order.total_price,
      note: order.note,
    });
  }, [order, setOrderValues, editMode]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Form
        handlers={handlers}
        action={order ? `orders/${order.id}` : 'orders/'}
        method={editMode ? 'PATCH' : 'POST'}
        axiosInstance={server}
        onSubmit={onSubmit}
        final={modifyDataForApi}>
        <DialogTitle>
          {editMode ? 'Edit Order' : 'Create Order'}
          <Divider />
        </DialogTitle>
        <DialogContent>
          {/* Customer Section */}
          <Typography variant="h6">Customer Details</Typography>
          <Input
            fullWidth
            placeholder="Name *" // Added star to placeholder
            margin="normal"
            name="customer_name"
          />
          <Input
            fullWidth
            margin="normal"
            placeholder="Email *" // Added star to placeholder
            name="customer_email"
          />
          <Divider sx={{ mt: 2, mb: 2 }} />
          {/* Shipping Section */}
          <Typography variant="h6">Shipping Address</Typography>
          <Input
            fullWidth
            margin="normal"
            name="address1"
            placeholder="Address1"
          />
          <Input
            fullWidth
            margin="normal"
            name="address2"
            placeholder="Address2"
          />
          <Input fullWidth margin="normal" name="city" placeholder="City" />
          <Input
            fullWidth
            margin="normal"
            name="country"
            placeholder="Country"
          />
          <Input fullWidth margin="normal" name="phone" placeholder="Phone" />
          <Input
            fullWidth
            margin="normal"
            name="zip"
            placeholder="Postal code"
          />
          <Input
            fullWidth
            margin="normal"
            name="company"
            placeholder="Company"
          />
          <Divider sx={{ mt: 2, mb: 2 }} />
          {/* Order Section */}
          <Typography variant="h6" mb={2}>
            Order Details
          </Typography>
          <FormControl fullWidth variant="outlined" margin="normal">
            <Select
              name="financial_status"
              value={handlers.values.financial_status}
              onChange={handleSelectChange}
              displayEmpty
              required>
              <MenuItem value="" disabled>
                Financial Status
              </MenuItem>
              {financialStatuses.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Input
            fullWidth
            margin="normal"
            name="totalPrice"
            placeholder="Price"
          />
          <Input
            fullWidth
            margin="normal"
            name="note"
            placeholder="Order Notes"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
