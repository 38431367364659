import React, { useState } from 'react';
import { Box } from '@mui/material';
import ListEditor from '../Editors/ListEditor';
import StringEditor from '../Editors/StringEditor';
import OptionsEditor from '../Editors/OptionsEditor';
import ObjectEditor from '../Editors/ObjectEditor';
import NumberEditor from '../Editors/NumberEditor'; // Import NumberEditor

const Editor = ({ setting, index, handlers, saveSettings, updateMutation }) => {
  const [listValue, setListValue] = useState(setting.value || []);
  const [isChanged, setIsChanged] = useState(false);
  const [stringValue, setStringValue] = useState(setting.value || '');

  const handleSaveString = () => {
    saveSettings(index, setting.key, stringValue);
  };

  const handleStringChange = e => {
    const { value } = e.target;
    setStringValue(value);
    handlers.setValues({ [`value-${index}`]: value });
  };

  const handleListSave = () => {
    saveSettings(index, setting.key, listValue);
    setIsChanged(false);
  };

  const handleAddItem = () => {
    setListValue([...listValue, '']);
    setIsChanged(true);
  };

  const handleDeleteItem = itemIndex => {
    setListValue(listValue.filter((_, i) => i !== itemIndex));
    setIsChanged(true);
  };

  const handleEditItem = (itemIndex, updatedValue) => {
    const newListValue = [...listValue];
    newListValue[itemIndex] = updatedValue;
    setListValue(newListValue);
    setIsChanged(true);
  };

  const handleOptionSelect = selectedOption => {
    handlers.setValues({ [setting.key]: selectedOption });
    saveSettings(index, setting.key, selectedOption);
  };

  const handleSaveNumber = newValue => {
    saveSettings(index, setting.key, newValue);
  };

  return (
    <Box mb={3} key={setting.key}>
      {setting.schema.type === 'string' && (
        <StringEditor
          name={setting.key}
          value={stringValue}
          description={setting.description}
          onChange={handleStringChange}
          onSave={handleSaveString}
        />
      )}

      {setting.schema.type === 'options' && (
        <OptionsEditor
          title={setting.key}
          description={setting.description}
          options={setting.schema.elements}
          value={setting.value}
          onSelect={handleOptionSelect}
          isPending={updateMutation.isPending}
        />
      )}

      {setting.schema.type === 'list' && (
        <>
          {setting.schema.elements.type === 'object' ? (
            <ObjectEditor
              title={setting.key}
              subtitle={setting.description}
              value={listValue}
              onSave={newList => {
                setListValue(newList);
                setIsChanged(false);
                saveSettings(index, setting.key, newList);
              }}
            />
          ) : (
            <ListEditor
              title={setting.key}
              subtitle={setting.description}
              value={listValue}
              isChanged={isChanged}
              isPending={updateMutation.isPending}
              onAdd={handleAddItem}
              onDelete={handleDeleteItem}
              onEdit={handleEditItem}
              onSave={handleListSave}
            />
          )}
        </>
      )}

      {setting.schema.type === 'number' && (
        <NumberEditor
          item={listValue}
          onSave={handleSaveNumber}
          title={setting.key}
          subtitle={setting.description}
        />
      )}
    </Box>
  );
};

export default Editor;
