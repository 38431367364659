import SearchIcon from '@mui/icons-material/Search';
import { FormControl, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { forwardRef } from 'react';

const SearchWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    maxWidth: '720px',
    minWidth: '240px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.custom.search.main,
    border: 'none',
    borderRadius: '10px',
}));

const IconWrapperLeft = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1.1, 1, 1.1),
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        },
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

const SearchBar = forwardRef((props, ref) => {
    const { onEnter, ...rest } = props;

    const onSubmit = e => {
        e.preventDefault();
        if (onEnter) onEnter(e);
    };
    return (
        <React.Fragment>
            <SearchWrapper>
                <IconWrapperLeft>
                    <SearchIcon />
                </IconWrapperLeft>
                <FormControl fullWidth>
                    <form onSubmit={onSubmit}>
                        <StyledInputBase
                            sx={{
                                flex: 1,
                            }}
                            inputRef={ref}
                            placeholder="Search..."
                            {...rest}
                        />
                    </form>
                </FormControl>
            </SearchWrapper>
        </React.Fragment>
    );
});

export default SearchBar;
