import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

export default function ActionIcon(props) {
    const {
        children,
        href,
        title,
        imageSx,
        sx,
        tooltipProps = {},
        ...rest
    } = props;

    return (
        <Tooltip title={title} {...tooltipProps}>
            <IconButton LinkComponent="a" sx={sx} href={href} {...rest}>
                {children}
            </IconButton>
        </Tooltip>
    );
}
