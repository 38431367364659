import React, { useState } from 'react';
import {
  Slider,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
} from '@mui/material';

const NumberEditor = ({ item, onSave, title, subtitle }) => {
  const [value, setValue] = useState(item.value || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSave = () => {
    onSave({ ...item, value });
  };

  return (
    <Card
      sx={{
        width: {
          xs: '100%',
          sm: '90%',
          lg: '400px',
        },
        my: 0.5,
      }}>
      <CardContent>
        <Box>
          <Typography
            textTransform="capitalize"
            variant="body1"
            fontWeight={600}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ my: 0.4 }}>
            {subtitle}
          </Typography>
          <Slider
            value={value}
            onChange={handleChange}
            min={item.schema?.min || 0}
            max={item.schema?.max || 100}
            valueLabelDisplay="auto"
          />
          <Typography variant="body1">Current Value: {value}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ mt: 2 }}>
            Save
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NumberEditor;
