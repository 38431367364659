import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMessage } from '../../components/Header';
import Loading from '../../components/Loading';
import useErrorHandler from '../../hooks/useErrorHandler';
import useLoader from '../../hooks/useLoader';
import { server } from '../../utils/axios';

const EditCreateCronScript = () => {
  const { showSuccess } = useMessage();
  const { loaderState } = useLoader();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const [scripts, setScripts] = useState([]);
  const [selectedScript, setSelectedScript] = useState('');
  const [name, setName] = useState('');
  const [schedule, setSchedule] = useState('');

  const handleScriptChange = event => {
    setSelectedScript(event.target.value); // Update selected script ID
  };

  // Fetch the scripts from the API
  const fetchScripts = useCallback(async () => {
    try {
      const response = await server.get('/scripts/');
      setScripts(response.data.results);
    } catch (error) {
      errorHandler(error);
    }
  }, [errorHandler]);

  const onSubmit = async event => {
    event.preventDefault(); // Prevent default form submission

    const payload = {
      name,
      schedule,
      script: selectedScript, // Send the selected script ID
    };

    try {
      await server.post('/scripts/cronscripts/', payload); // Ensure the correct URL
      showSuccess('Script saved successfully');
      navigate(-1); // Navigate back after success
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchScripts();
  }, [fetchScripts]);

  return (
    <Box
      bgcolor="background.paper"
      height="calc(100vh - 76px)"
      overflow="auto"
      display="flex"
      flexDirection="column"
      p={0}>
      <Grid container alignItems="center" sx={{ pr: 2 }}>
        <Grid item xs>
          <Typography variant="h5" fontWeight={600} color="primary" p={2}>
            New Cron Script
          </Typography>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" />
      {loaderState ? (
        <Loading message="Please wait, while your data is loading..." />
      ) : (
        <form onSubmit={onSubmit} style={{ flexGrow: 1, position: 'relative' }}>
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            mb={4}
            overflow="auto">
            <Grid
              container
              columnSpacing={5}
              rowSpacing={1}
              mb={2}
              alignItems="center">
              <Grid item xs={12} lg>
                <Typography variant="body2" fontWeight={600}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={12} lg={10}>
                <TextField
                  name="name"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={e => setName(e.target.value)} // Handle input change
                  sx={{ width: { xs: '100%', sm: '90%', lg: '316px' } }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              columnSpacing={5}
              rowSpacing={1}
              mb={2}
              alignItems="center">
              <Grid item xs={12} lg>
                <Typography variant="body2" fontWeight={600}>
                  Schedule
                </Typography>
              </Grid>
              <Grid item xs={12} lg={10}>
                <TextField
                  name="schedule"
                  variant="outlined"
                  size="small"
                  value={schedule}
                  onChange={e => setSchedule(e.target.value)} // Handle input change
                  sx={{ width: { xs: '100%', sm: '90%', lg: '316px' } }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" mb={2}>
              <Grid item xs={12} lg>
                <Typography variant="body2" fontWeight={600}>
                  Select Script
                </Typography>
              </Grid>
              <Grid item xs={12} lg={10}>
                <FormControl fullWidth size="small">
                  <InputLabel id="script-select-label">Script</InputLabel>
                  <Select
                    labelId="script-select-label"
                    id="script-select"
                    value={selectedScript}
                    onChange={handleScriptChange}
                    label="Script"
                    sx={{ width: { xs: '100%', sm: '90%', lg: '316px' } }}>
                    {scripts.map(script => (
                      <MenuItem key={script.id} value={script.name}>
                        {script.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Divider variant="fullWidth" sx={{ mt: 2 }} />
          </Box>

          <Stack
            direction="row"
            p={2}
            spacing={2}
            sx={{
              boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
              backgroundColor: 'background.paper',
              zIndex: 1100,
              position: 'fixed',
              bottom: 0,
              width: {
                xs: '100%',
                xm: `calc(100% - 280px)`,
              },
            }}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ textTransform: 'capitalize' }}>
              Save
            </Button>
            <Button
              onClick={() => navigate(-1)}
              sx={{
                bgcolor: 'background.default',
                border: '1px solid',
                borderColor: 'divider',
              }}>
              Cancel
            </Button>
          </Stack>
        </form>
      )}
    </Box>
  );
};

export default EditCreateCronScript;
