import { Stack, Modal, Card } from '@mui/material';
import { Box, Button, Typography } from '@mui/material';
import useLoader from '../../hooks/useLoader';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useCallback } from 'react';
import { server } from '../../utils/axios';
import { useNavigate } from 'react-router-dom';

export default function DeleteModal(props) {
    const { modal, scriptId } = props;

    const { modalState, closeModal } = modal;
    const { circular, startLoading, endLoading, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();

    // Update API call to delete a script using scriptId
    const deleteScript = useCallback(async () => {
        startLoading();

        try {
            await server.delete(`/scripts/${scriptId}/`);
            navigate('/scripts'); // Redirect to the scripts list after deletion
        } catch (e) {
            errorHandler(e);
        }

        endLoading();
    }, [startLoading, endLoading, errorHandler, navigate, scriptId]);

    return (
        <Modal
            open={modalState}
            onClose={closeModal}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Card
                sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
                    borderRadius: '18px',
                    overflowY: 'auto',
                    maxHeight: '80vh',
                    maxWidth: '600px',
                    width: '100%',
                    mx: 2,
                    '::-webkit-scrollbar': { display: 'none' },
                    p: 2,
                }}>
                <Box
                    sx={{
                        p: 3,
                        color: 'text.secondary',
                        fontWeight: 500,
                        letterSpacing: '1.2px',
                        fontSize: '14px',
                    }}>
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        color="primary"
                        mb={10}>
                        Delete the script?
                    </Typography>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={2}>
                        <Box textAlign="center">
                            <Button
                                disabled={loaderState}
                                variant="outlined"
                                sx={{
                                    fontWeight: '500',
                                    textTransform: 'capitalize',
                                    borderRadius: '8px',
                                    padding: '12px 28px',
                                }}
                                onClick={closeModal}>
                                Cancel
                            </Button>
                        </Box>
                        <Box textAlign="center">
                            <Button
                                disabled={loaderState}
                                color="error"
                                variant="contained"
                                onClick={deleteScript}
                                endIcon={circular}
                                sx={{
                                    fontWeight: '500',
                                    textTransform: 'capitalize',
                                    borderRadius: '8px',
                                    padding: '12px 28px',
                                }}>
                                Delete
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Card>
        </Modal>
    );
}
