import React from 'react';
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ActionIcon from '../../../components/ActionIcon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';

export default function ListEditor({
  title,
  subtitle,
  value,
  isChanged,
  isPending,
  onAdd,
  onDelete,
  onEdit,
  onSave,
}) {
  return (
    <Box
      mb={3}
      sx={{
        width: {
          xs: '100%',
          lg: '400px',
        },
        my: 0.5,
      }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}>
        <Box>
          <Typography
            textTransform="capitalize"
            variant="body1"
            fontWeight={600}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ my: 0.4 }}>
            {subtitle}
          </Typography>
        </Box>
        {isChanged && (
          <Button
            variant="contained"
            endIcon={<SaveIcon />}
            disabled={isPending}
            onClick={onSave}>
            Save
          </Button>
        )}
      </Stack>
      {value.map((item, index) => (
        <Stack key={index} direction="row" gap={1} mb={2}>
          <TextField
            fullWidth
            placeholder="Item"
            variant="outlined"
            value={item}
            disabled={isPending}
            onChange={e => onEdit(index, e.target.value)}
          />
          <ActionIcon
            title="Delete Item"
            onClick={() => onDelete(index)}
            disabled={isPending}
            sx={{ ml: 2 }}>
            <DeleteOutlineOutlinedIcon />
          </ActionIcon>
        </Stack>
      ))}
      <Divider>
        <ActionIcon title="Add Item" onClick={onAdd}>
          <AddCircleOutlineIcon />
        </ActionIcon>
      </Divider>
    </Box>
  );
}
