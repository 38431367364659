import React, { createContext, useContext, useEffect, useState } from 'react';
import Login from '../pages/auth/Login';
import { clearCookie } from '../utils/cookies';
import { server } from '../utils/axios';
import Loading from '../pages/auth/Loading';

export const authorizeContext = createContext();

const AuthorizationProvider = ({ children }) => {
  const [content, setContent] = useState(<Loading />);
  const [user, setUser] = useState({});
  const [allSettings, setAllSettings] = useState([]);

  const fetchSettings = async () => {
    try {
      const response = await server.get(`/settings/`);
      const settings = response.data;

      setAllSettings(settings);
    } catch (err) {
      console.log(err);
    }
  };

  const settings = {
    fetchSettings,
    get: key => allSettings.find(setting => setting.key === key),
    getValue: key => allSettings.find(setting => setting.key === key)?.value,
    all: () => allSettings,
  };

  const authorize = async (loggedIn, cb) => {
    if (loggedIn) {
      await fetchSettings();
      setContent(children);
    } else {
      setContent(<Login />);
    }
    if (typeof cb === 'function') cb(setUser);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await server.get(`/user/`);
        const user = response.data;

        authorize(true, setUser => setUser(user));
      } catch (err) {
        console.log(err);
        clearCookie('accessToken');
        authorize(false);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <authorizeContext.Provider
      value={{
        authorize,
        setUser,
        user,
        setContent,
        settings,
      }}>
      {content}
    </authorizeContext.Provider>
  );
};

export const useAuthorize = () => useContext(authorizeContext)?.authorize;
export const useUser = () => useContext(authorizeContext)?.user;
export const useSetUser = () => useContext(authorizeContext).setUser;
export const useSettings = () => useContext(authorizeContext).settings;

export default AuthorizationProvider;
